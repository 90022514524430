<template>
  <v-card>
    <v-card-title class="mb-5">
      <span class="text-h6">Thêm nhân sự xử lý</span>
    </v-card-title>
    <v-card-text>
      <v-autocomplete :error="validates.includes('id_employee')" v-model="id_employee" :items="employees"
        label="Chọn nhân sự*" dense outlined hide-details></v-autocomplete>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog">
        Đóng
      </v-btn>
      <v-btn color="primary" @click="addEmployee">
        Lưu
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "AddEmployee",
  props: {
    idFindGoods: {
      type: Number,
      default: () => 0
    },
    idPos: {
      type: Number,
      default: () => 0
    },
    processingEmployees: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    id_employee: null,
    validates: [],
    employees: [],
    isLoading: false,
  }),
  methods: {
    async addEmployee() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      try {
        await httpClient.post('/find-goods-manager-add-employee', {
          id_employee: this.id_employee,
          id_find_goods: this.idFindGoods
        })
        this.id_employee = null
        this.refreshData()
        this.isLoading = false
        this.closeDialog()
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)

      }
    },
    async getEmployees() {
      const { data } = await httpClient.post('/employee-active-my-pos', {
        id_pos: this.idPos,
        status: 10
      })
      const employees = []
      const processingEmployeeIds = [...this.processingEmployees].map(pe => (pe.id_employee))
      data.forEach(emp => {
        if (!processingEmployeeIds.includes(emp.id) && ![39, 152, 208, 488, 805].includes(emp.id_identity)) {
          employees.push({
            value: emp.id,
            text: emp.full_name || ''
          })
        }
      })
      this.employees = [...employees]
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    refreshData() {
      this.$emit('refreshData')
    },
    removeValidate(name) {
      this.validates = this.validates.filter(v => v !== name)
    },
  },
  mounted() {
    this.getEmployees()
  }
}
</script>

<style scoped></style>
